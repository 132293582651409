import {REREmployeeStatus} from "@/constants/request/RERConstants";
import {ResidencyStatus} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class PublicREREmployeeDTO {

    id!: number;
    status!: REREmployeeStatus;
    personId: number | null = null;
    companyName!: string;
    companyId!: number;
    name!: string;
    actualizationOnly!: boolean;
    residencyStatus: ResidencyStatus | null = null;
    ssnImage: FileMetaDTO | null = null;
    eadFrontImage: FileMetaDTO | null = null;
    eadBackImage: FileMetaDTO | null = null;
    greenCardFrontImage: FileMetaDTO | null = null;
    greenCardBackImage: FileMetaDTO | null = null;
    citizenPassportImage: FileMetaDTO | null = null;
    stateIdFrontImage: FileMetaDTO | null = null;
    stateIdBackImage: FileMetaDTO | null = null;
    voidCheckImage: FileMetaDTO | null = null;
    addressConfirmationImage: FileMetaDTO | null = null;
    preparedI9: FileMetaDTO | null = null;
    signedI9: FileMetaDTO | null = null;
    employmentStart!: Date;
    employeeId: number | null = null;

    // if it has already been registered
    ssnRequired = true;
    stateIdRequired = true;
    latestStateIdState: string | null = null;
    latestStateIdExpirationDate: Date | null = null;
    eadRequired = true;
    greenCardRequired = true;
    citizenPassportRequired = true;
    bankDetailsRequired = true;
    currentAccountNumber: string | null = null;
    currentAccountRoutingNumber: string | null = null;
    currentAccountBank: string | null = null;

    saveWithoutStateIdBack = false;
    stateIdIsValid = false;
    currentAccountIsValid = false;

    constructor(init?: Partial<PublicREREmployeeDTO>) {
        Object.assign(this, init);
    }
}
